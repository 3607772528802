import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Business Stratagy',
        description: 'In today’s competitive business environment, a strong and well-executed strategy is the key to achieving sustainable success. At our company, we specialize in partnering with businesses to develop and implement effective strategies that drive growth, enhance profitability, and deliver lasting results.'
    },
    {
        icon: '02',
        title: 'Website Development',
        description: 'Expand your digital presence and mobility with coherent web and mobile solutions tweaked to resonate with your audience. We have delivered dozens of web and mobile applications consistently score 90+ on Google PageSpeed Insights, load swiftly without long UI blocking, and boost revenues with above-average conversion rates.'
    },
    {
        icon: '03',
        title: 'Marketing & Reporting',
        description: 'Using an efficient mix of manual and automated testing methodologies, our team makes sure your software works as intended, minimizing potential errors and the cost of continuous quality assurance.'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;