import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About',
        description = 'Building a winning strategy is essential for any business aiming to stay competitive and achieve long-term success. At our company, we specialize in creating strategic frameworks that propel businesses to new heights. Through in-depth market analysis, competitor insights, and a deep understanding of your business, we develop innovative strategies that capitalize on your strengths and mitigate weaknesses. Our team remains dedicated to supporting you throughout the implementation phase, ensuring seamless execution and continual refinement of the strategy. With our expertise and commitment to excellence, we help businesses like yours soar to new levels of growth, profitability, and industry leadership. Elevate your business today with our winning strategy.';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;