import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Business Stratagy',
        description: 'In today’s competitive business environment, a strong and well-executed strategy is the key to achieving sustainable success. At our company, we specialize in partnering with businesses to develop and implement effective strategies that drive growth, enhance profitability, and deliver lasting results.'
    },
    {
        icon: <FiLayers />,
        title: 'Website & Mobile Development',
        description: 'Expand your digital presence and mobility with coherent web and mobile solutions tweaked to resonate with your audience. We have delivered dozens of web and mobile applications consistently score 90+ on Google PageSpeed Insights, load swiftly without long UI blocking, and boost revenues with above-average conversion rates.'
    },
    {
        icon: <FiUsers />,
        title: 'Marketing & Reporting',
        description: 'Using an efficient mix of manual and automated testing methodologies, our team makes sure your software works as intended, minimizing potential errors and the cost of continuous quality assurance.'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services',
        description = 'You can rely on a full scope of web and mobile development, quality assurance, and UX/UI design services';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/"><span className="text">Request Custom Service</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
